import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
import Testimonial from '../../components/Testimonial';
import { testingAuditService } from '../../../data/SiteConfig';
import { printGbpWithUsd } from '../../utils/formatting';
import ContactForm from '../../components/ContactForm';
import SimpleFaqList from '../../components/SimpleFaqList';
import Img from 'gatsby-image';
export const BookNowButton = ({
  showFooter
}) => <p className="text-center my-8">
    <a className="btn bg-primary-500 hover:bg-primary-600 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:shadow-outline font-sans" href="https://savvycal.com/paulswail/testing-audit">
      Book now
    </a>
    {showFooter && <>
        <br />
        <span className="inline-block font-sans text-sm text-gray-600 pt-4">
          Got questions before booking?{' '}
          <a href="/contact/" className="text-gray-700">
            Contact me
          </a>
          .
        </span>
      </>}
  </p>;
export const faqs = [// {
//   question: 'What happens after I apply?',
//   answer:
//     'I will review your message and reply by email, typically within one business day, with links to make payment and to schedule the initial call.',
// },
// {
//   question: 'When will we have our initial meeting?',
//   answer:
//     'After payment is received, I’ll provide you with a calendar booking link to schedule our workshop session.',
// },
{
  question: 'Do you have any any tech stack restrictions?',
  answer: 'Yes. Currently I only work with teams building AWS Serverless apps using Node.js (JavaScript or TypeScript) and one of the following deployment frameworks: Serverless Framework, SAM, CDK, SST.'
}, {
  question: 'What if we have literally NO tests in our codebase. Can an audit still help us?',
  answer: 'Yes! You actually have the most to gain from an audit in this situation. I will show you how to set up the testing infrastructure and give you a sequenced plan of where to find low hanging fruit so you start to see the benefits as early as possible.'
}, {
  question: 'Do you review front-end and back-end code?',
  answer: 'I will go into most depth on the back-end code as this is where most of the high impact bugs can occur in serverless applications. However, if you have front-end web code, I will review it as well and make suggestions here.'
}, {
  question: 'What does the timeline look like for the audit?',
  answer: 'I aim for a 2-week turnaround from our initial call to our review meeting. You can book the initial call via a calendar form which shows my available times. After this initial call and you granting me access to your codebase, I will start reviewing your code and writing up my recommendations. When I email you the report, I will also give you a link to schedule our review meeting.'
}, {
  question: 'How do we ensure that your suggestions are implemented and adhered to by developers?',
  answer: 'My recommendations will be prioritised so that you know which order to tackle them. I will also make recommendations on how to adapt your team’s workflows to help make testing stick for you. If you need extra support after the audit, I can offer an ongoing guidance option for a monthly fee (availability permitting).'
}, {
  question: 'Can you sign an NDA before getting access to our codebase?',
  answer: 'Yes. After payment is received, if you need me to sign an NDA before accessing your codebase, just let me know by email and I will be happy to do so. If you don’t have your own one, I can provide you with my mutual NDA document.'
}, {
  question: 'What are your payment terms?',
  answer: 'Payment is due in full before booking our initial meeting. I accept payments by credit card. VAT (if applicable in your country, e.g. UK and EU) is not included in the price. Businesses can provide their VAT number when making payment.'
}, {
  question: 'I still have more questions that I’d like to ask before booking.',
  answer: 'No worries. Email them to me at paul@serverlessfirst.com.'
}];
export const _frontmatter = {};
const layoutProps = {
  BookNowButton,
  faqs,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 className="page-heading font-regular text-center text-lg tracking-wider my-8 uppercase text-gray-600">
  Serverless Testing Audit
    </h1>
    <h2 className="font-regular text-center text-4xl my-8">
  Are production bugs stressing you out?
    </h2>
    <h3 className="text-center">
  Get a tailored plan of action for overhauling your AWS serverless app’s tests
  and empower your team to ship faster with confidence.
    </h3>
    <BookNowButton mdxType="BookNowButton" />
    <hr></hr>
    {
      /* PAIN */
    }
    <p>{`Bugs are a pain. They are a pain to debug. They are a pain to fix. They erode trust with your users and within your team.`}</p>
    <p>{`You `}<em parentName="p">{`know`}</em>{` that automated testing will mean fewer bugs make it into production. And you `}<em parentName="p">{`know`}</em>{` that your team should be writing many more tests.`}</p>
    <p>{`But `}<strong parentName="p">{`testing serverless applications is hard.`}</strong></p>
    <p>{`So much of your codebase is now Infrastructure-as-Code and the familiar unit test-dominated approaches hold much less value than they used to. You need integration and E2E tests running against real cloud resources to give you the confidence to deploy frequently to production.`}</p>
    <p>{`But integration and E2E tests can be `}<strong>{`slow, flaky and hard to configure`}</strong>{` if not done right. And when you add asynchronous event-driven use cases into the mix, just knowing what to test and how to verify correctness can be a major challenge for developers.`}</p>
    <p>{`And then there is the `}<strong parentName="p">{`time pressure.`}</strong></p>
    <p>{`Writing good tests takes time but there is always a new feature to be built. And not everyone in your team buys into the benefits of automating your testing efforts.`}</p>
    <p>{`All of the above inevitably results in your team writing fewer and fewer tests. And without sufficient test coverage, as your app grows, the problem snowballs. Production deployments become more and more fraught with angst and so slow, manual testing is needed.`}</p>
    <p>{`But since manual QA doesn’t scale, there’s only one way this is going to end: `}<strong parentName="p">{`slow, labour-intensive and infrequent releases`}</strong>{`.`}</p>
    <p>{`And now you’ve just cancelled out one of the primary benefits of going serverless in the first place.`}</p>
    <h2 className="text-4xl">
  You <span className="italic">can</span> get back to shipping fast with
  confidence.
    </h2>
    <p>{`Imagine having a comprehensive suite of reliable tests for your serverless app that catches most of the bugs before they get to production. Manual testing can be eliminated altogether and continuous delivery becomes so much easier to achieve.`}</p>
    <p>{`And as a nice side effect of having this test suite in place, your developers can easily write effective tests for new features by referencing patterns from the existing tests.`}</p>
    <p>{`The great thing with automated testing is that it can be incrementally improved over time—you don’t need to drop everything else to make it happen. As the quality and coverage of your test suite grows, releases become less stressful, faster and more frequent. And your developers and users will thank you for it.`}</p>
    <p>{`You now get to fully realise the benefits of your serverless architecture choice.`}</p>
    <h2 className="text-4xl">
  A Testing Audit will help you earn back trust in your codebase.
    </h2>
    <p>{`You’ll get a prioritised set of recommendations that you can plan into your delivery cycles as you incrementally grow the quality and coverage of your automated testing suite.`}</p>
    <p>{`Here’s how it works:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Initial Diagnostic Call`}</strong>{` – We will have a 30–60 minute Zoom call where we discuss your team's current development workflows and deployment pipelines, and the primary use cases of your application. We will then discuss the main issues you are having with your automated testing efforts (slowness, flakiness, low coverage) and what you have tried so far to address these.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Code Review`}</strong>{` – After the initial call, you provide me with access to your code repo and I will perform a review of the tests and your application code. After this, I will provide you with a written report containing prioritised recommendations of areas to fix/add, supplying code samples where appropriate.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Q & A Session`}</strong>{` – We will have a 1-hour zoom call where we will walk through the recommendations from the review together, allowing you to ask further questions. This meeting will be recorded and you will get a copy for your reference.`}</p>
      </li>
    </ol>
    <p>
  Price:{' '}
  <span className="font-bold">
    {printGbpWithUsd(testingAuditService.priceGBP)}
  </span>
  .
    </p>
    <p>
  Remember: quality and team velocity are interdependent, not a trade-off.{' '}
  <strong>
    Invest in testing and your speed will increase as a byproduct.
  </strong>
    </p>
    <BookNowButton showFooter mdxType="BookNowButton" />
    <section className="my-8">
  <h2 className="text-4xl">100% money-back guarantee.</h2>
  <p>
    If you are not happy after our initial call and before I start into the
    review process, I'll refund your fee in full immediately.
  </p>
    </section>
    <section className="my-12">
  <Testimonial authorName="Darren Gibney" authorTitle="Partner & CTO at 3Advance" authorPhotoUrl="/img/client-logos/darren-gibney.jpg" mdxType="Testimonial">
    <p>
      <strong>
        Paul’s focus on developer experience was probably the thing I most liked
        about his work with us.
      </strong>{' '}
      We frequently needed to onboard new team members and handoff projects to
      internal teams and this was key to long term success of projects.{' '}
      <strong>He raised the bar for our team</strong>, in many ways we’ve
      surpassed our previous stack in terms of IaC, CI/CD and testing.
    </p>
  </Testimonial>
  <Testimonial authorName="Kevin Xu" authorTitle="Founder, Westside Labs" authorPhotoUrl="/img/testimonials/kevin-x.jpg" mdxType="Testimonial">
    <p>Paul is a great teacher with deep knowledge of Serverless testing and applications as a whole.</p>
  </Testimonial>
    </section>
    <section>
  <h2 className="text-4xl">Still have questions?</h2>
  <SimpleFaqList faqs={faqs} mdxType="SimpleFaqList" />
    </section>
    <section className="bg-gray-100 -mx-10 my-12 p-10 rounded-lg">
  <h2 className="text-4xl my-0">Who are you?</h2>
  <div>
    <p>
      Hi, I’m Paul Swail. I’m an Independent Cloud Consultant helping
      development teams design, build, ship and operate serverless applications
      on AWS.
    </p>
    <p>
      I’ve helped many consulting clients and students in my Serverless Testing
      Workshops learn how to build robust delivery pipelines with a strong
      automated testing suite as their backbone.
    </p>
    <img src="/img/paulswail_2021-09_a-square.jpeg" className="mx-auto my-4 max-w-xl w-full" alt="Paul Swail" />
    <p>
      I’ve been a professional software developer/architect for over 20 years
      and ran my own SaaS business on AWS for seven years. I’ve been
      specialising in serverless since 2017 and write regularly about the
      opportunities and challenges that it brings.
    </p>
  </div>
    </section>
    <section id="applyForm" className="mt-10 lg:mt-2 mb-10 mx-auto">
  <BookNowButton showFooter mdxType="BookNowButton" />
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      